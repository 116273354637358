.raw-item {
  border: 1px solid transparent;
  font-size: 12px;
}

.raw-item:hover {
  border-color: rgb(134, 21, 9);
}

.raw-item.active {
  border-color: rgba(208, 20, 0, 1);
}

span.treegrid-expander {
  text-align: center;
}

span.treegrid-expander i.fa {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

span.treegrid-expander i.fa.fa-plus {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

span.treegrid-expander i.fa.fa-minus {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.traffics .table-striped td {
  background-color: white !important;
  --bs-table-bg-type: white !important;
  font-size: 12px;
}

.traffics .heading-item {
  background-color: rgba(35, 58, 116, 1);
  padding: 0 5px;
  border-radius: 5px;
  font-weight: bold;
  width: fit-content;
  font-size: 12px;
  color: white;
}

table.traffic-data {
  width: 90%;
}

table.traffic-data tr {
  height: 100%;
}

table.traffic-data th {
  font-size: 14px;
  color: rgba(0, 84, 166, 1);
  text-align: center;
  border: none;
  border-bottom: 1px solid rgba(49, 91, 215, 1);
}

table.traffic-data td {
  font-size: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  padding: 2px 0;
  border: none;
  border-bottom: 1px solid rgba(137, 138, 141, 1);
  cursor: pointer;
  height: 100%;
}

table.traffic-data tr td div {
  padding: 8px 0;
  height: 100%;
}
table.traffic-data tr td:first-child div {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

table.traffic-data tr td:last-child div {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

table.traffic-data tr:hover td div {
  background-color: rgba(101, 242, 134, 0.2);
}

table.traffic-data tr.active td div {
  background-color: rgba(101, 242, 133, 0.35);
}


@media(max-width:767px) {

  table.traffic-data {
    width: 100%;
  }

}